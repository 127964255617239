.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    width: 100px;
    height: 100px;
    border: 10px solid rgba(255, 0, 0, 0.2); /* Red color */
    border-top: 10px solid #ae1c3d; /* Red color */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Animation duration and timing */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  