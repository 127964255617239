.user-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

form {
  margin-top: 20px;
}

.user-datas {
  flex-basis: calc(45% - 10px);
  margin: 10px 5px;
  display: flex;
  align-items: left;
}

.user-data .err-msg {
  padding: 10px 20px;
  text-align: center;
  width: fit-content;
  color: #ae1c3d;
  font-weight: 700;
  background-color: #ae1c3d55;
  border-radius: 9px;
  position: relative;
  animation: slideInLeft 0.3s, slideOutLeft 0.3s forwards 4s;

  display: flex;
  flex-direction: row-reverse;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 30px;

}

.user-data .success-msg {
  padding: 10px 20px;
  margin: 10px 0;
  width: fit-content;

  text-align: center;
  color: #0f0e0e;
  font-weight: 700;
  background-color: #0cd51dfa;
  border-radius: 9px;
  position: relative;
  animation: slideInLeft 0.3s, slideOutLeft 0.3s forwards 4s;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 30px;
}

.odd {
  display: flex;
  flex-direction: column;
}

.even {
  display: flex;
  flex-direction: column;
}

.user-datas label {
  text-align: left;
  margin: 0px 0px 15px;
}





.user-datas div {
  width: 300px;
  padding: 10px;
}

.user-data button {
  padding: 10px;
  background-color: #AE1C3D;
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  font-size: large;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 30px;
}

.user-datas .err-msg {
  padding: 10px 20px;
  margin: 10px 0;
  text-align: center;
  color: #ae1c3d;
  font-weight: 700;
  background-color: #ae1c3d55;
  border-radius: 9px;
  position: relative;
  animation: slideInLeft 0.3s, slideOutLeft 0.3s forwards 4s;

}

.user-datas .success-msg {
  padding: 10px 20px;
  margin: 10px 0;
  text-align: center;
  color: #0f0e0e;
  font-weight: 700;
  background-color: #0cd51dfa;
  border-radius: 9px;
  position: relative;
  animation: slideInLeft 0.3s, slideOutLeft 0.3s forwards 4s;

}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  95% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
    white-space: nowrap;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0
  }
}

.user-datas[type="email"]:focus,
.user-datas[type="text"]:focus,
.user-datas[type="number"]:focus,

.user-datas[type="password"]:focus {
  border-color: red;
}

.hidden {
  display: none;
}

.user-datas[type="password"] {
  max-height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  border: 1px solid #e0e3e9;
  padding: 0 10px;
  padding-left: 5px;
  padding-right: 27px;
  outline: 0
}

@media screen and (max-width: 480px) {

  /* Adjust styles for smaller screens (e.g., tablets) */
  .user-datas {
    flex-basis: 100%;
    /* Full width on smaller screens */
    margin: 10px 0;
  }

  .user-datas label {
    text-align: left;
    margin-inline-start: 0;
    /* Reset margin */
    margin-bottom: 5px;
    /* Add spacing between label and input */
  }

  .user-datas div {
    width: 100%;
    padding: 5px;
  }

  .user-datas button {
    margin: 10px 0;
  }
}











/* Hide the default checkbox */
input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox style */
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
}

/* Create a custom checkbox */
.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ae1c3d;
  /* Change this color as needed */
  border-radius: 4px;
  /* Rounded corners */
  position: relative;
  margin-right: 10px;
  /* Spacing between checkbox and label */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Change background when checked */
input[type="checkbox"]:checked+.checkbox {
  background-color: #f3f3f3;
  /* Background color when checked */
  border-color: #ae1c3d;
  /* Border color when checked */
}

/* Custom checkmark */
.checkbox::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: #ae1c3d;
  /* Color of the checkmark */
  /* border-radius: 2px; */
  /* Round the corners */
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

/* Scale the checkmark when checked */
input[type="checkbox"]:checked+.checkbox::after {
  transform: translate(-50%, -50%) scale(1);
  /* Scale up the checkmark */
}