@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

.login-main {
    min-height: 100vh;
    display: flex;
   
    padding:10vh 15vw ;
    background-color: #eee;
        position: relative;

}

.login-main .card {
    height:80vh;
    width: 35vw;
    z-index: 12;
}
.input-text .error {
    padding: 10px 20px;
    margin: 10px 0;
    text-align: center;
    color: #ae1c3d;
    font-weight: 700;
    background-color: #ae1c3d55;
    border-radius: 9px;
    position: relative;
    animation: slideInLeft 0.3s, slideOutLeft 0.3s forwards 4s;

  }
  
  @keyframes slideInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideOutLeft {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    95%{
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
white-space: nowrap;
      height: 0;
      width: 0;
      margin:0;
      padding:0
    }
  }

.login-main .card .form {
    width: 100%;
    height: 80vh;
    display: flex
}

.login-main .card .left-side {
    width: 40vw;
    position: absolute;
    left: 35vw;
top:-2.5vh;

    height: 85vh !important;
    border-radius: 10px;
    z-index: -2;
    overflow: hidden
}

.left-side img {
    height: 100%;
    width: 100%;
    
    object-fit: fill;
}

.login-main .card .right-side {
    width: 37vw;    
    height: 80vh;
    background-color: #fff;
    position: absolute;
    border-radius: 10px;
    padding:  10vh 3vw;     
       z-index: 12;

}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box
}



.card {
    height: 380px;
    width: 300px;
    background-color: #fff;
    position: relative;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
    
}

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center
}

.text p {
    font-size: 13px;
    color: #bac4d4;
    font-weight: 700
}

.input-text {
    position: relative;
    margin-top: 4vh;
}

input[type="email"],input[type="text"],input[type="number"] {
    height: 10vh;
    max-height: 45px;
    width: 100%;
    border: none;
    background-color: #fff;
    border-radius: 9px;
    border: 1px solid #e0e3e9;
    padding: 0 10px;
    padding-left: 30px;
    outline: 0;
    font-size: 13px
}
input[type="email"]:focus,
input[type="text"]:focus,
input[type="password"]:focus
 {
  border-color: red;
}
.hidden {
    display: none;
  }

input[type="password"] {
height: 10vh;
    max-height: 45px;    width: 100%;
    border: none;
    border-radius: 9px;
    border: 1px solid #e0e3e9;
    padding: 0 10px;
    padding-left: 30px;
    padding-right: 27px;
    outline: 0
}

.fa-envelope-o {
    position: absolute;
    left: 10px;
    cursor: pointer;
    top: 15px;
}

.fa-key {
    position: absolute;
    left: 12px;
    top: 0px;
    cursor: pointer;
    font-size: 15px
}

.input-text .fa-eye-slash {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 15px;
    cursor: pointer
}

.fa-eye {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 15px;
    cursor: pointer
}

.buttons {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center
}
.back-to-login {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center
}

.buttons button {
    height:10vh;
    max-height: 45px;
    width: 100%;
    border: none;
    background-color: #AE1C3D;;
    border-radius: 9px;
color: #FFF;
font-family: 'Roboto condensed';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;    outline: 0;
    cursor: pointer;
    transition: all 0.5s
}
.back-to-login button {
    height:7vh;
    max-height: 45px;
    width: 70%;
    border: none;
    background-color: #AE1C3D;;
    border-radius: 9px;
color: #FFF;
font-family: 'Roboto condensed';
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;    outline: 0;
    cursor: pointer;
    transition: all 0.5s
}


.createaccount {
    margin-top: 3vh;
    display: flex;
    margin-inline: auto;
    color: #2B3B41;
font-family: 'Roboto Condensed';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    
}
.createaccount p{
    text-align:end;
    margin: auto;
}

.createaccount a:link{
    text-decoration: none;
}
.createaccount .red{
font-family: 'Roboto condensed';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    color:#AE1C3D ;
;
    
}
.forgot{
    height: 30px;
    margin: 10px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: end;
}
.container-box {
  display: block;
  color: rgba(43, 59, 65, 0.80);
font-family: Roboto;
font-size: 14px;
font-style: normal;
text-align: start;
font-weight: 400;
line-height: normal;
  position: relative;
  padding-left: 25px;
  
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 2px;
border: 1px solid rgba(0, 0, 0, 0.40);
background: #FFF;
}

/* On mouse-over, add a grey background color */
.container-box:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-box input:checked ~ .checkmark {
  background-color: #AE1C3D;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-box input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-box .checkmark:after {
  left: 3px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
p a{
        color: #2B3B41;
text-align: center;
font-family: 'Roboto Condensed';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
        

}

.warning {
    border: 1px solid red !important
}

.type_password {
    border: 1px solid red !important
}
@media (max-width: 768px) {
    .login-main .card .left-side{
        display: none;
    }
    
    .login-main .card .right-side{
        width: 70vw;
        padding: 5vh 3vw;
    }
    .login-main .card {
        width: 50vw;
    }

}
@media (max-width: 440px) {
    .login-main .card {
        width: 100vw;
    }
    .login-main .card .right-side{
        width: 100%;
    }
    .login-main{
        padding: 5vh 5vw;

    }
}