/* colors */
.black {
  color:#000;
}

.dashboard-main {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  font-family: Roboto Condensed;
}
div h3 {
  margin-block: 1rem 0;
}
.user-content meter {
  width: 11vw;
  height: 15px;
}
.user-set .meter-value {
  padding: auto;
  font-weight: 600;

  margin: auto;
}
.dashboard-main .left-box {
  width: 65%;
  padding: 0 1vw;
  height: 85vh;
  margin-top: 1rem;
}
.dashboard-main .right-box {
  width: 35%;
  padding: 1vw;
}
.total-summary {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
}
.set-1,
.set-2 {
  width: 100%;
  display: flex;
}
.set-1 .summary-box {
  width: 46%;
  height: 165px;
  margin: 3%;
  color: #2b3b41;
  border-radius: 15px;
  border: 2px solid #dfe2e3;
  padding: 3%;
}
.set-2 .summary-box {
  width: 46%;
  height: 165px;
  margin: 3%;
  color: #2b3b41;
  border-radius: 15px;
  border: 2px solid #dfe2e3;
  padding: 3%;
}
.summary-box .count {
  font-size: 36px;
  font-weight: 800;
}
.left-box h3 {
  font-family: Roboto Condensed;
}
.right-box h3 {
  font-family: Roboto Condensed;
}
.right-box .user-type {
  margin: 15px auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1),
    -4px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width: 94%;
  height: 320px;
  overflow-y: scroll;
}
/* Hide the scrollbar track and buttons */
.right-box .user-type::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

/* Define the scrollbar thumb (the draggable part) */
.right-box .user-type::-webkit-scrollbar-thumb {
  background-color: #ae1c3d; /* Set the color of the thumb */
  border-radius: 5px; /* Set the border radius of the thumb */
}

/* Define the scrollbar track (the background) */
.right-box .user-type::-webkit-scrollbar-track {
  border-radius: 5px;
   background-color: #949090; /* Set the color of the track */
}

/* Define the scrollbar corner (between the vertical and horizontal scrollbar) */
.right-box .user-type::-webkit-scrollbar-corner {
  background-color: #333; /* Set the color of the corner */
}

.right-box .user-type-main {
  width: 100%;
}
.right-box .user-alert-main {
  width: 100%;
  margin-top: 2rem;
}
.right-box .user-alert {
  margin: 15px auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1),
    -4px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width: 94%;
  pointer-events: none;

}
.left-box .active-alarm {
  margin: 15px auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1),
    -4px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width: 94%;
  /* pointer-events: none; */

}
.pie-content .percent {
  font-size: 2rem;
}
.piecharts {
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 230px;
}
.pie-1 {
  display: flex;
width: 100%;
  border-radius: 15px;
  background: #fff;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1),
    -4px 0px 8px 0px rgba(0, 0, 0, 0.1);
  margin: 15px 15px;
  position: relative;
}
.pie-2 {
  margin: 15px 15px;
  width: 100%;

  display: flex;
  position: relative;
  border-radius: 15px;
  background: #fff;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1),
    -4px 0px 8px 0px rgba(0, 0, 0, 0.1);
}
.pie-1 .pie-content {
  position: absolute;
  left: 57%;
  top: 35%;
  padding: 10px;
}
.pie-2 .pie-content {
  position: absolute;
  left: 57%;
  top: 35%;
  padding-inline:10px ;
}
.pie-content p {
  font-weight: 600;
  font-size: 16px;
}
.stat-chart {
  margin: 15px auto;
  padding-block: 20px;
  border-radius: 15px;
  background: #fff;
  width: 98%;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1),
    -4px 0px 8px 0px rgba(0, 0, 0, 0.1);
}
.summary-box p {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.user-set {
  display: flex;
  padding: 4%;
}
.user-content {
  padding: 0 5%;
  display: flex;
  width: 100%;

}
.user-content div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
}
.user-content .subscriber-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
div .sub-name {
  padding-inline: 15px;
  align-self: center;
  width: max-content;
  margin-bottom: 0;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.user-list .sub-name{
  width: 80%;
}
div .last-seen {
  padding-inline: 15px;
  font-size: 14px;
  align-self: center;

  margin-bottom: 0;
  font-weight: 400;
  text-align: right;
}
div .isactive {
  padding-inline: 15px;
  font-size: 14px;
  align-self: center;

  margin-bottom: 0;
  font-weight: 400;
  text-align: right;
}
.alert-last-seen p{
  text-align: right;
  padding-inline: 15px;
  margin-bottom: 0px;
}
.user-content .icon-avatar {
  width: 40px;
  aspect-ratio: 1;
  height: 40px;
  border-radius: 50%;
}

.user-content meter::-webkit-meter-optimum-value {
  background-color: #af1e3e;
}
.user-content meter::-webkit-meter-bar {
  background-color: #f0f0f0;
}
.user-alert-set {
  display: flex;
  padding: 5%;
}
.user-alert-content {
  padding: 0 2%;
  width: 100%;
}
.pie-1 svg {
  margin-left: 5%;
}
.pie-2 svg {
  margin-left: 5%;
}
.user-alert-content .item-start,
.item-end {
  font-weight: 600;
  font-size: 16px;
  font-family: Roboto Condensed;

  margin-bottom: 0;
}
.user-alert-content .item-set {
  display: flex;
  justify-content: space-between;
}
.user-alert-content .item-set {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

@media (min-width: 1400px) {

.pie-1 svg {
  margin-left: 10%;
}
.pie-2 svg {
  margin-left: 10%;
}
}
@media (max-width: 1200px) {
  .total-summary {
    display: flex;
    flex-direction: column;
  }

  .pie-1 .pie-content {
    left: 52%;
  }
  .pie-2 .pie-content {
    left: 52%;
  }
  .pie-1 {
    left: 0px;
  }
  .pie-2 {
    left: 0px;
  }
  .pie-1 svg {
    margin-left: 8%;
  }
  .pie-2 svg {
    margin-left: 8%;
  }
  .dashboard-main {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .dashboard-main .left-box {
    width: 100%;
    height: fit-content;
    padding: 10px;
  }
  .user-content meter {
    width: 15vw;
    height: 15px;
  }
  .dashboard-main .right-box {
    /* margin-top: 570px; */
    display: flex;
    width: 100%;
  }
  .user-type .user-set {
    font-size: 18px;
  }
  .right-box h3 {
    padding-left: 10px;
    font-family: Roboto Condensed;
  }
  .right-box .user-type-main {
    margin-top: 2rem;
  }
}
@media (max-width: 950px) {
  .piecharts {
    flex-direction: column;
    width: 96%;
    height: 440px;
  }
  .pie-1 svg {
    margin-left: 30%;
  }
  .pie-2 svg {
    margin-left: 30%;
  }
  
}
@media (max-width: 848px) {
  .dashboard-main .left-box {
    width: 100%;
  }
  .dashboard-main .right-box {
    width: 100%;
  }
}

@media (max-width: 769px) {
  .pie-1 .pie-content {
    left: 60%;
  }
  .piecharts {
    flex-direction: row;
    width: 100%;
    height: 220px;
  }
  .user-content meter {
    width: 19vw;
    height: 15px;
  }
  .pie-2 .pie-content {
    left: 60%;
  }
  .pie-1 {
    left: 0px;
  }
  .pie-1 svg{
    margin-left: 10%;
  }
  .pie-2 svg{
    margin-left: 10%;
  }
  .pie-2 {
    left: 0px;
  }
  .total-summary {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .dashboard-main {
    width: 100%;
  }
  .dashboard-main .left-box {
    width: 100%;
    padding: 0 1vw;
    height: fit-content;
  }
  .dashboard-main .right-box {
    width: 100%;
    padding: 1vw;
  }
  .right-box .user-type {
    width: 95%;
  }
  .right-box .user-alert {
    width: 95%;
  }
}
@media (max-width: 710px) {
  .right-box {
    flex-direction: column;
  }
  .user-content meter {
    width: 41vw;
    height: 15px;
  }
  .user-alert-content .item-start,
  .item-end {
    font-size: 16px;
  }
  .user-content .sub-name{
    font-size: 24px;
  }
  .user-content .last-seen,.isactive{
    font-size: 20px;
  }
  .user-content .icon-avatar{
    width: 60px;
    height: 60px;
  }
  .user-content div {
    display: flex;
    flex-direction: row;
  }
  .item-set span {
    font-size: 14px;
  }
  .piecharts {
    flex-direction: column;
    width: 98%;
    margin-inline: auto;
    height: 440px;
  }
  .pie-1 svg {
    margin-left: 30%;
  }
  .pie-2 svg {
    margin-left: 30%;
  }
  .pie-1,
  .pie-2 {
    /* left: 20%; */
    margin :0;
    width: 100%;
  }
  .pie-1 .pie-content {
    left: 55%;
  }
  .pie-2 .pie-content {
    left: 55%;
  }
}
@media (max-width: 480px) {

  .pie-1,
  .pie-2 {
    /* left: 15%; */
    width: 100%;
  }
  .pie-1 svg {
    margin-left: 10%;
  }
  .pie-2 svg {
    margin-left: 10%;
  }
  .user-content .sub-name{
    font-size: 16px;
  }
  .user-content .last-seen,.isactive{
    font-size: 14px;
    padding-inline: 0;
    margin-bottom: 0;
    align-self: center;
  }
  .user-content .icon-avatar{
    width: 40px;
    height: 40px;
  }
  .user-content div {
    display: flex;
    flex-direction: row;
  }
  .user-content .alert-last-seen {
    display: flex;
    flex-direction: column;
  }
  .pie-1 .pie-content {
    left: 55%;
  }
  .pie-2 .pie-content {
    left: 55%;
  }
}
@media (max-width: 390px) {
  .pie-1,
  .pie-2 {
    left:0;
    width: 100%;
  }
  .pie-1 svg {
    margin-left: 0;
  }
  .pie-2 svg {
    margin-left: 0;

  }
  .pie-1 .pie-content {
    left: 50%;
  }
  .pie-2 .pie-content {
    left: 50%;
  }
}
@media (max-width: 300px) {
  
.piecharts{
  height: 400px;
}
.pie-1 .pie-content {
  left: 55%;
}
.pie-2 .pie-content {
  left: 55%;
}
.pie-1 svg{
  padding: 0 15px;
  height: 170px ;
}
.pie-1,.pie-2{
  height: 170px;
}
.pie-2 svg{
  padding: 0 15px;
  height: 170px ;

}

.pie-content p{
  font-size: 16px;
}
.pie-content .percent{
  font-size: 18px;
}
}
/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ae1c3d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
