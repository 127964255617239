.container-1{
  display: flex;
  padding-left: 2.5%;
  width: 100%;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1)
}
.searchbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   
}
.filterview{
    width: 30%;
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
   
}
.searchbox .search{
    width:240px;
    background-color: #fff;
    position: relative;
}
.searchbox .icon-search{
    margin-block:5px ;
    transform: translateX(-50px);
}
h2 {
    margin-bottom: 20px;
}
.filterview1{
    width: 100%;
   
}
.selectview{
    width: 100px;
    height: 38px;
    border-color: #000000;
    border-radius: 8px;
    margin-inline: 10px;
}
.button-border{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 8px;
    width: 100px;
    height: 38px;
    border: 1px solid #000;
   
}
.listview{
    margin-top: 20px;
}
@media (max-width:1200px){
    .container-1{
        width: 99%;
    }
}
@media (max-width:1024px){
    .container-1{
        width: 98%;
    }
}
@media (max-width:848px){
    .container-1{
        width: 98%;
    }
}
@media (max-width:769px){
    .container-1{
        width: 100%;
    }
}