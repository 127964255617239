.App {
  
    width: 100vw;
    height: 100vh;
}

.AppHeader {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px 4px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
