.search{
    width: 50%;
    height: 70px;
    background-color: #2b3b41;
    position: relative;
}
.header .ham-icon {
  font-size: 30px;
  margin-top: 20px;
  margin-left: 20px;
  color: #ffffff;
}

.icon-search{
    position: absolute;
    margin-block:5px ;
    transform: translateX(-50px);
}
.header {
    width: 100vw;
    height: 80px;
    max-height: 100px;
    background-color: #2b3b41;
    display: flex; 
    z-index:888;
    position: fixed;
    top: 00px;
    user-select: none;

}
.logoview{
    width: 200px;
    
    padding-left: 4vw; 
}
.logoview img{
        width: 200px;
        height: 6rem;
        
    }
.titleview{
    width: 70vw;
    margin:auto 5vw;
    
    color: white;
}
.titleview p{
        font-size:2rem;
        

}
/* .searchview {
    margin-block: auto;
    width:25vw;
    margin-right: 6vw;
}

.search{
    height: 30px;
    width: 250px;
} */
.accountview{
    width: 7vw;
    text-align: end;
    margin-inline-end: 2vw;
    position: relative;
    
    
}
.profile-box{
position: absolute;  
top:80px;
left: -90px;
  background-color: #fff;
    width: fit-content;
    height: fit-content;
    padding: 5px 10px ;
    border-radius:10px ;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1),
    -4px 0px 8px 0px rgba(0, 0, 0, 0.1);
}
.profile-box ul{
    list-style-type: none;
}
.profile-box ul li{
    text-align: left;
    padding: 5px ;

}
/* .user{
    padding-right: 2vw;
} */
.icons{
    color: white;
    font-size: 35px;
    
    margin: 25px 1vw;
}
.dummy-space{
   
    display:none;
}
.header .ham-icon{
    display: none;
}
@media (max-width:769px){
    .header .ham-icon {
  font-size: 30px;
  display: block;
  margin-top: 25px;
  margin-left: 20px;
  color: #ffffff;
}
    
.logoview{
    width: 14vw;
    padding-inline: 0;
    margin-left: 1vw;
    padding-block: 10px;
    
}
.searchview input[type="text"]{
    height: 35px;
}
.icon-search{
    margin-block: 0px;
    height: 25px;
    margin-block: 5px;
}
.logoview img{
        width: 14.5vw;
        height: 4.8rem;
        
    }
.titleview{
    width: 40vw;
    margin-inline:8vw;
}
.titleview p{
    font-size:1.5rem;
    

}
.searchview{
    width:30vw;
    margin-right:0;
}
.search{
    height: 20px;
}
}
@media (max-width:480px){
    .titleview p{
        font-size: 1rem;
    }
}
@media (max-width:480px){
    .logoview{
        width: 70vw;
        padding-inline:10vw ;
    }
    .accountview{
        width: 10vw;
    }
    .logoview img {
        width: 50vw;
    }
    .titleview{
        display: none;
    }
}
@media (max-width: 300px) {
    .logoview{
        width: 60vw;
    }
    .logoview img {
        width: 45vw;
    }
}
