* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
:root {
  --sidebar-width: 230px;
}

.container {
  display: flex;
  flex-direction: column;
}
.body {
  display: flex;
  background-color: #ffffff;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.logout {
  padding-top: 0vh;
  z-index:22;
  background-color: #f9f9f9;
  color: #91999c;
}
.footer-logo {
  margin-inline: 25%;
  margin-bottom: 5vh;
}
.copy {
  color: #2b3b41;
  z-index:22;
  background-color: #f9f9f9;
  
  text-align: center;
  font-size: 14px;
  padding: 20px;
  font-family: Roboto Condensed;
  font-weight: 500;
}
body::-webkit-scrollbar{
  width: 0px;
}
main {
  width: calc(100vw - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  padding: 10px 1vw;
  height: 60vh;
  margin-top: 80px;
  background-color: #fff;
  color:"#2B3B41";
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #2b3b41;
  color: white;
  padding: 10px 15px;
  border: none;
  display: none;
  position: absolute;
}

.openbtn:hover {
  background-color: #2b3b41;
}
.sidebar {
  background-color: #f9f9f9;
  color: #91999c;
  height: 87vh;
  margin-top:80px;
  min-width: 250px;
  width: 18vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s;
  padding-top: 15px;
  white-space: nowrap;
  position: fixed;
  z-index: 22;
}
.sidebar::-webkit-scrollbar{
  width: 0px;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;
  padding: 8px 15px;
  gap: 15px;
  color: rgba(43, 59, 65, 0.5);
}

.link:hover {
  color: #91999c;
}

.active .icon {
  color: #ae1c3d;
}
.active .link_text {
  color: #2b3b41;
  font-size: 18px;
  font-family: Roboto Condensed;
  font-weight: 600;
}

.icon,
.link_text {
  font-size: 18px;
  font-family: Roboto Condensed;
}
@media (max-width: 848px) {
  main {width: calc(100vw - var(--sidebar-width));
    margin-left: var(--sidebar-width);
  }
}

@media (max-width: 769px) {
    main {
  width: 100vw;
  margin-left: 0vw;
  height: 60vh;
  margin-top: 80px;
  background-color: #fff;
}

.logout {
  padding-top: 0vh;
  z-index:22;
  background-color: #f9f9f9;
  color: #91999c;
  
}
.copy{
  align-self: end;
}
}
@media (min-width: 1440px) {
  main{
    margin-left: 17vw;
  }
}
