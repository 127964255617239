.change-password div{
    width: 300px;
padding: 10px;
}
.change-password button{
    margin: 0 10px;
    padding: 10px;
    background-color:#AE1C3D ;
    color: #fff;
    border: none;
    border-radius: 10px;
    
}
.change-password .err-msg {
    padding: 10px 20px;
    margin: 10px 0;
    text-align: center;
    color: #ae1c3d;
    font-weight: 700;
    background-color: #ae1c3d55;
    border-radius: 9px;
    position: relative;
    animation: slideInLeft 0.3s, slideOutLeft 0.3s forwards 4s;

  }
  .change-password .success-msg {
    padding: 10px 20px;
    margin: 10px 0;
    text-align: center;
    color: #0f0e0e;
    font-weight: 700;
    background-color: #0cd51dfa;
    border-radius: 9px;
    position: relative;
    animation: slideInLeft 0.3s, slideOutLeft 0.3s forwards 4s;

  }
  
  @keyframes slideInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideOutLeft {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    95%{
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
white-space: nowrap;
      height: 0;
      width: 0;
      margin:0;
      padding:0
    }
  }
  .change-password[type="email"],.change-password[type="text"] {
    height: 10vh;
    max-height: 45px;
    width: 100%;
    border: none;
    background-color: #fff;
    border-radius: 9px;
    border: 1px solid #e0e3e9;
    padding: 0 10px;
    padding-left: 5px;
    outline: 0;
    font-size: 13px
}
.change-password[type="email"]:focus,
.change-password[type="text"]:focus,
.change-password[type="password"]:focus {
  border-color: red;
}
.hidden {
    display: none;
  }

.change-password[type="password"] {
height: 10vh;
    max-height: 45px;    width: 100%;
    border: none;
    border-radius: 9px;
    border: 1px solid #e0e3e9;
    padding: 0 10px;
    padding-left: 5px;
    padding-right: 27px;
    outline: 0
}
