.pagination {
  /* width: 180px; */
  float: right;
  background-color: #fff;
  /* border-top: 1px solid #e0e0e0; */
 margin-top: 5px;
  /* display: flex;
  justify-content: space-between; */
}
.pagi_icon {
  padding: 4px 8px;
 
  cursor: pointer;
}
.grey {
  color: rgba(0, 0, 0, 0.6);
}
.light-grey {
  color: rgba(0, 0, 0, 0.182);
}